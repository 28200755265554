.sharePopover{
    width: 20vw;
    min-width: 250px;
}
.person {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px 0 0 20px;
    overflow-y: auto;
}
.top:last-child{
    margin-bottom: 0.5rem;
}

.avatar {
    margin-left: 0.5rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: #CCC;
}

.person:hover {
    background-color: #33343F;
    cursor: pointer;
}

.info {
    flex-grow: 1;
    display: flex;
    padding-left: 1rem;
    align-items: center;
}

.name {
    font-size: 1.2rem;
    width: 60%;
}
.shareButton{
    width: 40%;
}
button{
    background-color: red;
    color: white;
    border-radius: 20px;
    padding: 0.5rem 1rem 0.5rem 1rem;
    font-size: 1rem;
    text-align: center;
}
.bottom{
    display: flex;
    align-items: center;
    width: 100%;
}
.label{
    font-size:0.8rem;
}
.shareWays{
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-around;
    font-size: 1.5rem;
}
.qrcode>div{
    display: flex;
    justify-content: center;
    align-items: center;
}
.copy>div{
    display: flex;
    justify-content: center;
    align-items: center;
}