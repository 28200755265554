.Searchpage {
    width: 100%;
    height: 90vh;
    background-color: #1A1823;
    display: flex;
}

.search {
    padding: 30px;
    padding-bottom: 0;
    flex-grow: 1;
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(215px, 314fr));
    grid-auto-rows: minmax(43vh, 43vh);
    row-gap: 20px;
    column-gap: 20px;
}
.nothing{
    color: antiquewhite;
    font-size: 1rem;
    margin: 0 10% 0 10%;
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.video{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.closeVideo{
    position: absolute;
    top: 3%;
    left: 3%;
    color: #CCC;
    background-color: rgb(95, 91, 105,0.5);
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    z-index: 1000;
}
.closeVideo:hover{
    cursor: pointer;
    background-color: rgb(95, 91, 105,0.8);
}
.search::-webkit-scrollbar{
    width: 10px;
}
.search::-webkit-scrollbar-track{
    background-color: transparent;
}
.search::-webkit-scrollbar-thumb{
    background-color: rgb(204, 204, 204,0.3);
    border-radius: 10px;
}
.search::-webkit-scrollbar-thumb:hover{
    background-color: rgb(204, 204, 204,0.3);
}