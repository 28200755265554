.singleContainer{
    display: flex;
    padding: 15px;
    border-radius: 5px;
    z-index: 1;
}
.avatarContainer{
    width: 10%;
    height: 100%;
}
.avatar{
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: #fff;
}
.content{
    display: flex;
    width: 90%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.name{
    font-size: 1.2rem;
    font-weight: 600;
    padding-left: 5%;
    margin-bottom: 5%;
}
.text{
    font-size: 1rem;
    padding-left: 5%;
    word-wrap: break-word;
    text-align: start;
    color: #fff;
}
.time{
    font-size: 0.8rem;
    padding-left: 5%;
    margin-top: 5%;
}
.interactive{
    width: 100%;
    display: flex;
    padding-top: 5%;
    display: flex;
}
.interactiveItem{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.interactiveItem:hover{
    cursor: pointer;
}
.littleText{
    font-size: 0.8rem;
    margin-left: 1%;
}
