.outside {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: rgb(0, 0, 0);
    position: relative;
}

.videoContainer {
    flex-grow: 1;
    height: 90vh;
    background-color:transparent;
    display: flex;
    flex-direction: column;
    position: relative;
}

img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(50px);
    z-index: 0;
}

.playerContainer {
    width: 100%;
    height: 90%;
    object-fit: contain;
    position: relative;
    z-index: 100;
}

.controlContainer {
    flex-shrink: 0;
    width: 100%;
    height: 10%;
    background-color: transparent;
    z-index: 100;
}