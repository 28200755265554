.commentArea{
    width: 25vw;
    min-width: 400px;
    flex-shrink: 1;
    height: 90vh;
    background-color: rgb(0,0,0,0.5);
    color: #ccc;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
    overflow-y: auto;
    overflow-x: hidden;
}
.commentTop{
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    padding-top: 5%;
}
.topAreaLeft{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.commentTitle{
    font-size: 1.5rem;
}
.close{
    font-size: 2rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1000;
}
.close:hover{
    cursor: pointer;
}
.topAreaRight{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 5%;
}
.commentList{
    width: 100%;
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.commentArea::-webkit-scrollbar{
    width: 10px;
}
.commentArea::-webkit-scrollbar-track{
    background-color: transparent;
}
.commentArea::-webkit-scrollbar-thumb{
    background-color: rgb(204, 204, 204,0.3);
    border-radius: 10px;
}
.commentArea::-webkit-scrollbar-thumb:hover{
    background-color: rgb(204, 204, 204,0.3);
}
.sendArea{
    height: 8vh;
    width: 25vw;
    min-width: 400px;
    background-color: transparent;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.sendInput{
    width: 80%;
    min-height: 50%;
    border-radius: 0.5rem;
    border: 1px solid #ffffff;
    outline: none;
    padding: 0.5rem;
    font-size: 1rem;
    background-color: rgb(255,255,255,0.9);
    word-wrap: break-word;
}
.sendIcon{
    position: absolute;
    right:10%;
    font-size: 1.5rem;
    color: red;
    display: flex;
    align-items: center;
}
.empty{
    width: auto;
    height: 11vh;
}