.uploadContainer {
    width: 30vw;
    height: 60vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-width: 400px;
    min-height: 400px;
    overflow: hidden;
}
.uploadButton{
    height: 20%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
}
.title{
    height: 40%;
    width: 100%;

}
.classify{
    height: 20%;
    width: 100%;
}
.submit{
    height: 20%;
    width: 100%;
    background-color: blueviolet;
}
.classify{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.close{
    position: absolute;
    top: 1%;
    right: 1%;
    font-size: 2rem;
    color: #CCC;
}
.close:hover{
    cursor: pointer;
    color: #FE2C55;
}
.classifyTitle{
    font-size: 1rem;
    color: #CCC;
}
.classifyContent{
    width: 80%;
    height: 100%;
    display: flex;
    font-size: 1rem;
    align-items: center;
    justify-content: space-around;
}