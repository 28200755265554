.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
