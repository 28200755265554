.controlContainer {
    width: 100%;
    background-color: transparent;
    height: 100%;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.topContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.progress {
    -webkit-appearance: none;
    /* 禁用默认样式 */
    appearance: none;
    width: 100%;
    height: 2px;
    background-color: transparent;
    background: #ccc;
    background-size: 100% 100%;
}

.progress::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
    background: #f44336;
    cursor: pointer;
    
}

.progress::-moz-range-thumb {
    /* Firefox */
    -webkit-appearance: none;
    appearance: none;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
}

.bottomContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.leftContainer {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.button {
    font-size: 1rem;
    color: white;
    padding: 0 5% 0 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.playedTime {
    font-size: 1rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.rightContainer {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.volume {
    width: 10rem;
    height: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.volume {
    -webkit-appearance: none;
    /* 禁用默认样式 */
    appearance: none;
    width: 100%;
    height: 2px;
    background-color: transparent;
    background: #ccc;
    background-size: 100% 100%;
}
.volume::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
    
}