.personal {
    background-color: #1A1823;
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
}
.personal:hover{
    cursor: pointer;
}
.personalPage{
    padding-top: 30px;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 0;
    overflow-y: auto;
}
.personalInfo{
    width: 100%;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}
.personalLeft{
    width: 60%;
    height: 100%;
    background-color: transparent;
    display: flex;
}
.avatarContainer{
    width:30%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.avatar{
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    background-color: #FFF;
}
.info{
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #FFF;
}
.name{
    font-size: 2rem;
    font-weight: 600;
    padding-left: 5%;
    margin-bottom: 5%;
    text-align: start;
}
.countContainer{
    display: flex;
    padding-left: 5%;
}
.count{
    font-size: 1.2rem;
    font-weight: 600;
    padding-left: 5%;
    margin-bottom: 5%;
    text-align: start;
    display: flex;
}
.count:first-child{
    padding-left: 0;
}
.title{
    color: #CCC;
    padding-right: 1rem;
}
.sign{
    color: #FFF;
    text-align: start;
    padding-left: 5%;
    font-size: 1rem;
    word-wrap: break-word;
}
.personalRight{
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.buttonContainer{
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: end;
}

.button{
    height: 100%;
    width: 25%;
    font-size: 1rem;
    border-radius: 10px;
    border: 1px solid #272933;
    background-color: #272933;
    color: #E6E6E7;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
}
.notfollow{
    background-color: #ff0f0f;
}
.button:hover{
    cursor: pointer;
    background-color: #474a5b;
}
.notfollow:hover{
    background-color: #b51d1d;
}
.classify{
    padding-top: 1rem;
    width: 100%;
    display: flex;
    font-size: 1.3rem;
}
.classifyTitle{
    padding-left: 5%;
    color: #CCC;
    font-weight: 200;
}
.classifyTitle:hover{
    cursor: pointer;
}
.classifyTitleActive{
    padding-left: 5%;
    color: #ff0f0f;
    font-weight: 600;
}
.videoContainer{
    margin-top: 2rem;
    flex-grow: 1;
    padding-bottom: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(215px, 314fr));
    grid-auto-rows: minmax(43vh, 43vh);
    row-gap: 20px;
    column-gap: 20px;
}
.nothing{
    font-size: 1rem;
    margin: 0 10% 0 10%;
    width: 80%;
    height: 100%;
    min-width: 300px;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.video{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.closeVideo{
    position: absolute;
    top: 3%;
    left: 3%;
    color: #CCC;
    background-color: rgb(95, 91, 105,0.5);
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    z-index: 1000;
}
.closeVideo:hover{
    cursor: pointer;
    background-color: rgb(95, 91, 105,0.8);
}
.personalPage::-webkit-scrollbar{
    width: 10px;
}
.personalPage::-webkit-scrollbar-track{
    background-color: transparent;
}
.personalPage::-webkit-scrollbar-thumb{
    background-color: rgb(204, 204, 204,0.3);
    border-radius: 10px;
}
.personalPage::-webkit-scrollbar-thumb:hover{
    background-color: rgb(204, 204, 204,0.3);
}